// Hosted UI redirect urls
const localRedirectURL = "http://localhost:3000/";
const devRedirectURL = "https://yardmgmt-dev.aditrologistics.nu/";
const testRedirectURL = "https://yardmgmt-tst.aditrologistics.nu/";
const stageRedirectURL = "https://yardmgmt-stg.aditrologistics.nu/";

export const awsconfig = {
  Auth: {
    // Amazon Cognito Region

    region: "eu-west-1",

    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
};

export const authConfig = {
  domain: "yardmgmt-stg.auth.eu-west-1.amazoncognito.com",
  scope: [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin",
  ],
  redirectSignIn: stageRedirectURL,
  redirectSignOut: stageRedirectURL,
  responseType: "token",
};
